/*!
 * Minimal Mistakes Jekyll Theme 4.24.0 by Michael Rose
 * Copyright 2013-2020 Michael Rose - mademistakes.com | @mmistakes
 * Licensed under MIT (https://github.com/mmistakes/minimal-mistakes/blob/master/LICENSE)
*/

/* Variables */
@import "minimal-mistakes/variables";

/* Mixins and functions */
@import "minimal-mistakes/vendor/breakpoint/breakpoint";
@include breakpoint-set("to ems", true);
@import "minimal-mistakes/vendor/magnific-popup/magnific-popup"; // Magnific Popup
@import "minimal-mistakes/vendor/susy/susy";
@import "minimal-mistakes/mixins";

/* Core CSS */
@import "minimal-mistakes/reset";
@import "minimal-mistakes/base";
@import "minimal-mistakes/forms";
@import "minimal-mistakes/tables";
@import "minimal-mistakes/animations";

/* Components */
@import "minimal-mistakes/buttons";
@import "minimal-mistakes/notices";
@import "minimal-mistakes/masthead";
@import "minimal-mistakes/navigation";
@import "minimal-mistakes/footer";
@import "minimal-mistakes/search";
@import "minimal-mistakes/syntax";

/* Utility classes */
@import "minimal-mistakes/utilities";

/* Layout specific */
@import "minimal-mistakes/page";
@import "minimal-mistakes/archive";
@import "minimal-mistakes/sidebar";
@import "minimal-mistakes/print";

/* Modifications to base minimal-mistakes theme */

// Font size variables are defined in `_sass/minimial-mistakes/_variables.scss`
// Base page font size defined by `.page__content` in `_sass/minimal-mistakes/_pare.scss`

/* Colors */
$uiuc-orange-color: #FF552E;
$uiuc-blue-color: #13294B;
$uiuc-altgeld-color: #DD3403;

$h1-color: $dark-gray;
$h2-color: $light-gray;
$h3-color: $lighter-gray;
$emphasis-color: $uiuc-orange-color;
$muted-emphasis-color: $uiuc-blue-color;
$light-emphasis-color: mix(#fff, $uiuc-orange-color, 50%);
$light-muted-emphasis-color: mix(#fff, $uiuc-blue-color, 50%);

// $text-color: $dark-gray !default;
$text-color: $gray !default;

/* Padding */
$padding-medium: 1em;
$padding-small: 0.5em;
$padding-smaller: 0.25em;
$padding-smallest: 0.125em;

/* Team page */
$figure-team-width: 200px;
figure-team {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 2em 0;
  img {
    width: $figure-team-width;
    border-radius: $border-radius;
    -webkit-transition: $global-transition;
    transition: $global-transition;
    margin-left: 2em;
  }
}
figcaption-team {
  align-self: flex-start;
  // border-left: 0.25em solid $uiuc-orange-color;
  padding-left: 0.75em;
  margin-left: 1em;
  margin-bottom: 0.5em;
  
  a {
    -webkit-transition: $global-transition;
    transition: $global-transition;
    // text-decoration: none;
    // &:hover {
    //   text-decoration: underline;
    // }
  }

  span {
    margin-bottom: 0.5em;
    font-family: $global-font-family;
    font-size: $doc-font-size;
    color: $text-color;

    &.name {
      display: block;
      font-family: $header-font-family;
      font-size: $h-size-large;
    }

    &.info {
      display: block;
      color: $muted-text-color;
      font-size: $type-size-default;
      // a {
      //   -webkit-transition: inherit;
      //   transition: inherit;
      //   color: inherit;
      //   text-decoration: inherit;
      //   &:hover {
      //     text-decoration: inherit;
      //   }
      // }
    }

    &.logo {
      display: inline-block;
      color: $muted-text-color;
      font-size: $type-size-default;
      padding-right: 10px;
      // i {
      //   font-size: $doc-font-size;
      // }
      // a {
      //   -webkit-transition: inherit;
      //   transition: inherit;
      //   color: inherit;
      //   text-decoration: inherit;
      //   &:hover {
      //     text-decoration: inherit;
      //   }
      // }
    }
  }
}
nofigure-ugrad {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1em 0;
  img {
    width: $figure-team-width;
    border-radius: $border-radius;
    -webkit-transition: $global-transition;
    transition: $global-transition;
    margin-left: 2em;
  }
}
caption-ugrad {
  align-self: flex-start;
  // border-left: 0.25em solid $uiuc-orange-color;
  padding-left: 0.75em;
  margin-left: 1em;
  // margin-bottom: 0.5em;

  span {
    // margin-bottom: 0.5em;
    font-family: $global-font-family;
    font-size:  $doc-font-size;
    color: $text-color;

    &.name {
      display: block;
      font-family: $header-font-family;
      font-size: $h-size-default;
    }

    &.info {
      display: block;
      color: $muted-text-color;
      a {
        -webkit-transition: inherit;
        transition: inherit;
        color: inherit;
        text-decoration: inherit;
        &:hover {
          text-decoration: inherit;
        }
      }
    }

    &.logo {
      display: inline-block;
      color: $muted-text-color;
      padding-right: 10px;
      i {
        font-size: $doc-font-size;
      }
      a {
        -webkit-transition: inherit;
        transition: inherit;
        color: inherit;
        text-decoration: inherit;
        &:hover {
          text-decoration: inherit;
        }
      }
    }
  }
}

/* Research page */
.funding-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2em;
  border-bottom: 0.1em solid $border-color;
  img {
    height: 50px;
    -webkit-transition: $global-transition;
    transition: $global-transition;
    margin-bottom: 1em;
  }
  > a{
    display: inline-block;
    color: $muted-text-color;
    padding-right: 15px;
  }
}

/* Figures */
$figure-width: 400px;
figure-full-caption {
  display: -webkit-box;
  display: flex;
  flex-flow: column;  /* caption below image */
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2em 0;
  // caption font size defined by `figcaption` in `_sass/minimal-mistakes/_base.scss`

  img,
  iframe,
  .fluid-width-video-wrapper {
    margin-bottom: 1em;
  }

  img {
    width: $figure-width;
    border-radius: $border-radius;
    -webkit-transition: $global-transition;
    transition: $global-transition;
  }

  > a {
    display: block;
  }

  &.half {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(50% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }

  &.third {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(33.3333% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }
}
$figure-width-small: 300px;
figure-full-caption-small {
  display: -webkit-box;
  display: flex;
  flex-flow: column;  /* caption below image */
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2em 0;

  img,
  iframe,
  .fluid-width-video-wrapper {
    margin-bottom: 1em;
  }

  img {
    width: $figure-width-small;
    border-radius: $border-radius;
    -webkit-transition: $global-transition;
    transition: $global-transition;
  }

  > a {
    display: block;
  }

  &.half {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(50% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }

  &.third {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(33.3333% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }
}
$figure-width-very-large: 800px;
figure-full-caption-very-large {
  display: -webkit-box;
  display: flex;
  flex-flow: column;  /* caption below image */
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2em 0;

  img,
  iframe,
  .fluid-width-video-wrapper {
    margin-bottom: 1em;
  }

  img {
    width: $figure-width-very-large;
    border-radius: $border-radius;
    -webkit-transition: $global-transition;
    transition: $global-transition;
  }

  > a {
    display: block;
  }

  &.half {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(50% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }

  &.third {
    > a,
    > img {
      @include breakpoint($small) {
        width: calc(33.3333% - 0.5em);
      }
    }

    figcaption {
      width: 100%;
    }
  }
}

/* Wide layout (no left or right sidebar) */
.wide_full {
  .page {
    float: left;
    width: 100%;
    @include breakpoint($large) {
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint($x-large) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .page__related {
    @include breakpoint($large) {
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint($x-large) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
// TODO
$right-sidebar-width-tiny: 50px !default;
.wide_partial {
  .page {
    float: right;
    width: 90%;
    @include breakpoint($large) {
      float: right;
      width: calc(100% - #{$right-sidebar-width-tiny});
      padding-right: $right-sidebar-width-tiny;
    }

    @include breakpoint($x-large) {
      width: calc(100% - #{$right-sidebar-width});
      padding-right: $right-sidebar-width;
    }
  }

  .page__related {
    @include breakpoint($large) {
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint($x-large) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/* Archive layouts */
.archive {
  margin-top: 1em;
  margin-bottom: 2em;

  @include breakpoint($large) {
    float: right;
    // width: calc(100% - #{$right-sidebar-width-narrow});
    // padding-right: $right-sidebar-width-narrow;
    width: 100%;
  }

  @include breakpoint($x-large) {
    // width: calc(100% - #{$right-sidebar-width});
    // padding-right: $right-sidebar-width;
    width: 100%;
  }
}

/* Buttons */
.button_general {
	background-color: $code-background-color;
  border: 1px solid $border-color;
  color: $link-color;
  font-size: $type-size-small;
  padding-left: $padding-medium;
  padding-right: $padding-medium;
  padding-top: $padding-small;
  padding-bottom: $padding-small;
  text-decoration: none;
  &:hover {
    border-color: $border-color;
    color: $link-color-hover;
  }
}

/* Publications (modifed from al-folio) */
// font size seems smaller than expected
.publications {
  font-size: $type-size-default;
  h2.year {
    color: $muted-text-color;
    font-size: $h-size-default;
    border-top: 1px solid $border-color;
    border-bottom: none;
    padding-top: $padding-small;
    text-align: right;
  }
  ol.bibliography {
    // list-style: none;
    // padding: 0;
    margin-top: 0;

    li {
      margin-bottom: $padding-medium;
      // margin-bottom: 1em;
      // .abbr {
      //   height: 2rem;
      //   margin-bottom: 0.5rem;
      //   abbr {
      //     display: inline-block;
      //     background-color: var(--global-theme-color);
      //     padding-left: 1rem;
      //     padding-right: 1rem;
      //     a {
      //       color: white;
      //       &:hover {
      //         text-decoration: none;
      //       }
      //     }
      //   }
      //   .award {
      //     color: var(--global-theme-color) !important;
      //     border: 1px solid var(--global-theme-color);
      //   }
      // }
      .title {
        // font-size: $type-size-large;
        font-weight: bold;
      }
      .author {
        // font-size: $type-size-large;
        // link for co-authors
        // a {
        //   border-bottom: 1px dashed;
        //   &:hover {
        //       border-bottom-style: solid;
        //       text-decoration: none;
        //   }
        // }
        // emphasis on PI
        > em {
          // border-bottom: 1px solid;
          font-style: normal;
          // color: $emphasis-color;
        }
      }
      .venue {
        // font-size: $type-size-large;
        font-style: italic;
      }
      .links {
        padding-top: $padding-smallest;
        a.btn {
          background-color: $code-background-color;
          border: 1px solid $border-color;
          color: $link-color;
          font-size: $type-size-smaller;
          padding-left: $padding-medium;
          padding-right: $padding-medium;
          padding-top: $padding-small;
          padding-bottom: $padding-small;
          &:hover {
            border-color: $border-color;
            color: $link-color-hover;
          }
        }
      }
      // .hidden {
      //   font-size: 0.875rem;
      //   max-height: 0px;
      //   overflow: hidden;
      //   text-align: justify;
      //   transition-property: 0.15s ease;
      //   -moz-transition: 0.15s ease;
      //   -ms-transition: 0.15s ease;
      //   -o-transition: 0.15s ease;
      //   transition: all 0.15s ease;

      //   p {
      //     line-height: 1.4em;
      //     margin: 10px;
      //   }
      //   pre {
      //     font-size: 1em;
      //     line-height: 1.4em;
      //     padding: 10px;
      //   }
      // }
      // .hidden.open {
      //   max-height: 100em;
      //   transition-property: 0.15s ease;
      //   -moz-transition: 0.15s ease;
      //   -ms-transition: 0.15s ease;
      //   -o-transition: 0.15s ease;
      //   transition: all 0.15s ease;
      // }
      // div.abstract.hidden {
      //   border: dashed 1px var(--global-bg-color);
      // }
      // div.abstract.hidden.open {
      //   border-color: var(--global-text-color);
      // }
    }
  }
}